import React from "react";

class SendMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div className="row row-received-message">
                <span style={{ width: "60%", textAlign: "left", fontSize: "0.8em", paddingLeft: "8px" }}>
                    {new Date(this.props.richiesta.DATA).toLocaleDateString().replace(/\//g, ".")}
                </span>
                <span className="bold" style={{ width: "40%", textAlign: "right", paddingRight: "8px" }}>
                    {this.props.nomeazienda}:
            </span>
                <div className="col-12 send-message">
                    <span style={{ maxWidth: "100%", wordBreak: "break-all" }}>
                        {this.props.messaggio.TESTO}
                    </span>
                </div>
            </div>
        );
    }
}
export default SendMessage;
