import React from "react";
export default class ManageCreateTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { Template: null };
    }
    componentDidMount() {
        import('../Page/CreateTempalte/CreateTemplate' + this.props.match.params.idTemplate + '.js').then(Template => {
            this.setState({ Template: Template.default });
        });
    }
    render() {
        let { Template } = this.state;
        return (
            <div>
                {this.state.Template && <Template {...this.props} ></Template>}
            </div>
        );
    }
}