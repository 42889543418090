import React from "react";

function SupportTicket(props) {
    return (
        <div>
            <div data-toggle="modal" data-target={"#chat"} className={props.idSelected === props.richiesta.ID_RICHIESTA ? "ticket-div-active" : "ticket-div"} onClick={() => { props.getMessaggio(props.richiesta.ID_RICHIESTA); }}>
                <div className="row">
                    <div className="support-ticket">
                        {props.richiesta.STATO === 2 && <div className="support-ticket-stato-2">
                        </div>}
                        {props.richiesta.STATO === 1 && <div className="support-ticket-stato-1">
                        </div>}
                        {props.richiesta.STATO === 0 && <div className="support-ticket-stato-0">
                        </div>}
                    </div>
                    <div className="col-11 nopadding-support-ticket"><span className="ticket">{props.richiesta.TITOLO}</span></div>
                </div>
                <p className="data_support">{new Date(props.richiesta.DATA).toLocaleDateString().replace(/\//g, ".")}</p>
            </div>
        </div>);
}
export default SupportTicket;
