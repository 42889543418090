import React from "react";

function SceltaCategoria(props) {

    return (
        <li className={props.scelta[props.numero] ? "liselected" : ""} onClick={e=>{props.cambiaStile(props.numero)}}>
            &bull; {props.nome}
        </li>
    )
}
export default SceltaCategoria;