import React from "react";
import CroppedTemplate from "../CroppenTemplate.js";
import common from "../../Component/common.js";

class CambiaImmagineProfilo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.aggiornaImage = this.aggiornaImage.bind(this);
        this.UploadImage = this.UploadImage.bind(this);
        this.CambiaImmagineProfilo = this.CambiaImmagineProfilo.bind(this);
    }

    componentDidMount() {
        this.setState({ imgProfilo: this.props.imgAttuale, imgVecchia: this.props.imgAttuale })
    }
    async CambiaImmagineProfilo() {
        let result = await common.eseguiPostRequestWithAuthorization(
            "/cambiaImmagineProfilo",
            { urlImmagine: this.state.imgProfilo }
        );
        if (result === null || result === undefined) {
            return false;
        } else if (result.token) {
            localStorage.setItem("token", result.token)
            return true;
        }
    }
    aggiornaImage(src, name, file, nomefile, nomeoriginale) {
        this.setState({ imgProfilo: src });
        if (file) {
            file.name = nomeoriginale;
            this.setState({ [nomefile]: file });
        }
    }

    async UploadImage(nomeFile) {
        let file = this.state[nomeFile];
        const data = new FormData();
        data.append("file", file, file.name);
        fetch(process.env.REACT_APP_BACKEND + "/uploadImageProfilo", {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                Authorization: localStorage.getItem("token"),
                credentials: "include"
            },
            body: data
        })
            .then(res => res.json())
            .then(
                async result => {
                    if (result && result.imgpath) {
                        this.setState({ imgProfilo: result.imgpath });
                        if (await this.CambiaImmagineProfilo()) {

                            window.location.reload();
                        }
                    }
                },
                error => {
                    alert(error);
                }
            );
    }
    render() {
        return (

            <div className="resetImmagineProfilo">
                <CroppedTemplate
                    aggiornaImage={this.aggiornaImage}
                    Nome={"imgProfilo"}
                    immagineAttuale={this.state.imgProfilo}
                    NomeFile={"img_profiloFile"}
                    heightProporzionale={10}
                    widthProporzionale={10}
                    UploadImage={this.UploadImage}
                ></CroppedTemplate>
            </div>
        );
    }
}
export default CambiaImmagineProfilo;