export async function onSearchSubmit(term, orientation = "all", page = 1) {
    const apiid = "16890724-7a9c6e82feaf822d25534d52f";
    const response = await fetch('https://pixabay.com/api/?key=' + apiid + '&page=' + page + 'orientation=' + orientation + '&lang=it&safesearch=true&q="' + encodeURIComponent(term) + '"').then(function (response) {
        return response.json();
    }).then(function (data) {
        return data;
    });
    return response

}
