import React from "react";

class ReceivedMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div className="row row-received-message">
                
                <span className="bold" style={{ width: "40%", textAlign: "left", paddingLeft: "8px" }}>
                    Tally:
            </span>
            <span style={{ width: "60%", textAlign: "right", fontSize: "0.8em", paddingRight: "8px" }}>
                    {new Date(this.props.richiesta.DATA).toLocaleDateString().replace(/\//g, ".")}
                </span>
                <div className="col-12 received-message" >
                    <span style={{ maxWidth: "100%", wordBreak: "break-all" }}>
                        {this.props.messaggio.TESTO}
                    </span>
                </div>

            </div>
        );
    }
}
export default ReceivedMessage;
