import React from "react";

function NewPassword(props) {

    return (

        <div >
            <div>
                <span style={{margin: "0 auto", width: "100%" }} className="ticket" data-toggle="collapse" href={"#" + props.id} role="button" aria-expanded="false" aria-controls="collapseExample">
                    {props.nome}
                </span>
                <input disabled={props.disabled} type="password" style={{ width: "100%",marginTop:"5px" }} name={props.nome} placeholder={props.placeholder} onChange={props.changeValuePassword}></input>
            </div>
        </div>

    );
}
export default NewPassword;